import { BasePerimeter } from '@argon/iam/perimeters'
import { ROLES } from '@argon/iam/utils'

export default new BasePerimeter({
  purpose: 'lead',
  can: {
    read(lead) {
      return this.hasAction(lead, 'read')
    },
    update(lead) {
      return this.hasAction(lead, 'update')
    },
    destroy(lead) {
      return this.hasAction(lead, 'delete')
    },
    manage() {
      return this.hasPermission('lead:manage')
    },
    list() {
      return this.hasPermission('lead:read')
    },
    create() {
      return this.hasPermission('lead:create')
    },
    administrate() {
      return this.isAdmin()
    },
    atLeastAdvisor() {
      let org = this.child.getters['organizations/activeOrg']
      return this.isLevelOrAbove(org, ROLES.MEMBER)
    },
    atLeastManager() {
      let org = this.child.getters['organizations/activeOrg']
      return this.isLevelOrAbove(org, ROLES.MANAGER)
    }
  }
})
