<template>
  <BaseNavItem :title="$t('labels.leads')" icon="$vuetify.icons.values.leads" :route="targetRoute" :visible="visible" />
</template>

<script>
import { mapState } from 'vuex'
import { BaseNavItem } from '@argon/app/components'
import { NAMESPACE as ORG_NAMESPACE } from '@argon/iam/organizations/store'
import leadPerimeter from '../perimeters'

export default {
  name: 'LeadsNavItem',
  components: { BaseNavItem },
  perimeters: [leadPerimeter],
  computed: {
    ...mapState(ORG_NAMESPACE, ['activeOrgRefname']),
    targetRoute() {
      let params = this.activeOrgRefname
        ? { orgRef: this.activeOrgRefname, boardRef: 'process-lead' }
        : { ...this.$route.params, boardRef: 'process-lead' }
      return { name: 'leadTableView', params }
    },
    visible() {
      return this.$lead.isAllowed('manage')
    }
  }
}
</script>
